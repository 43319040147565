<script setup lang="ts">
import xirr from 'xirr'
import { endOfDay, format as dateFormat } from 'date-fns'
import { flatten } from 'lodash'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useExtendedI18n } from '@/i18n'
import { useCan } from '@/modules/auth/composables/use-can'
import { useInvestorStore } from '@/modules/entity/stores/investor-store'
import {
  TheContent,
  VButton,
  VDescriptionList,
  VIcon,
  VSection,
  VSkeletonBar,
  VStats,
} from '@/modules/shared/components'
import useFeatureFlags from '@/modules/shared/composables/use-feature-flags'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import { locateData } from '@/modules/shared/utils/data'
import { Money, initialMoney, sum, toNumber } from '@/modules/shared/utils/money'
import { rails_url } from '@/modules/shared/utils/rails'
import { dpi, moic, roi, rvpi, tvpi } from '@/modules/shared/utils/reporting'
import { format, parse } from '@/modules/shared/utils/v-table'
import CallsBarChart from '../components/calls-bar-chart.vue'
import DistributionsBarChart from '../components/distributions-bar-chart.vue'
import TheHeader from '../components/the-header.vue'
import TheNav from '../components/the-nav.vue'
import { useCallData } from '../composables/use-call-data'
import { useDistributionData } from '../composables/use-distribution-data'
import { useEntityStore } from '../stores/entity-store'
import { useInvestingVehicleStore } from '../stores/vehicle-store'
import { getChartData, transformEntity } from '../utils/entity'
import { usePortfolioStore } from '../stores/portfolio-store'
import { PortfolioSection } from '@/modules/shared/components'
import { createOptions } from '@/modules/shared/utils/form'
import { useAuthStore } from '@/modules/auth/stores/auth-store'

///////////////////////////////////////////////////////////////////////////////
// Utils
///////////////////////////////////////////////////////////////////////////////

function capitalize(str: string) {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
}

const formatEntityType = (type: string) => {
  const entity_types = {
    fund: 'fund',
    spv: 'SPV',
    gp: 'GP',
  }

  return entity_types[type]
}

///////////////////////////////////////////////////////////////////////////////
// Setup
///////////////////////////////////////////////////////////////////////////////

const { can } = useCan()
const { n, t } = useExtendedI18n()
const route = useRoute()
const entityStore = useEntityStore()
const investorStore = useInvestorStore()
const vehicleStore = useInvestingVehicleStore()
const portfolioStore = usePortfolioStore()
const authStore = useAuthStore()

const { flags } = useFeatureFlags(['debug'], { route })

const asOfDate = ref(dateFormat(new Date(), 'yyyy-MM-dd'))

const cid = computed(() => `${route.params.entity_type}:${route.params.entity_id}`)
const isAdmin = computed(() => vehicleStore.items.get(cid.value) === 'admin')

const currentEntity = ref({
  address: {
    city: null,
    country: null,
    line1: null,
    line2: null,
    postal_code: null,
    state: null,
  },
  tax_id: null,
  currency: 'USD',
  entity_type: route.params.entity_type as string,
  investors: [1],
  managers: [],
  selected_subscriptions: [],
  type: 'LLC',
  is_venture360_the_administrator: false,
  questionnaire: {
    expected_activity: [],
    uses_formation_documents: false,
    transferred_from_assure: false,
    part_of_consolidated_return: false,
    disregarded_entity_for_tax_purposes: false,
    expects_to_receive_k1: false,
    expects_activity_on_annual_basis: false,
  },
  has_investments: false,
  date_established: null,
  structure: {
    capital_call_frequency: null,
    carried_interest: 0,
    hurdle: null,
    interest_catch_up_provision: null,
    investment_period: null,
    management_fee: 0,
    preferred_return: 0,
    waterfall: null,
  },
})
const investorStats = computed(() => investorStore.state.stats || {})
const today = endOfDay(new Date()).getTime()
// prettier-ignore
const filteredStats = computed(() =>
  investorStore.state.selectedKeys
    .map((investor) => investorStats.value[investor])
    .filter((stats) => !!stats)
    .map((stats) => locateData(stats, today))
)

const skeleton = ref(true)

onMounted(async () => {
  await vehicleStore.fetchVehicles()
  // @ts-ignore
  await entityStore.fetchEntity({
    id: route.params.entity_id as string,
    type: route.params.entity_type as string,
  })

  currentEntity.value = entityStore.items.get(cid.value)

  await investorStore.reset()
  // prettier-ignore
  await Promise.all([
    investorStore.listStats(),
    investorStore.listProfiles(),
    portfolioStore.fetchInvestments(cid.value, { as_of: asOfDate.value })
  ])

  skeleton.value = false
})

///////////////////////////////////////////////////////////////////////////////
// Portfolio performance
///////////////////////////////////////////////////////////////////////////////
const investorOptions = computed(() => createOptions(investorStore.state.profiles, { label: 'name' }))
const investments = computed(() => {
  if (investorStore.state.selectedKeys.length === investorOptions.value.length) {
    if (authStore.is_site_or_group_admin) {
      return portfolioStore.investments.filter((investment) => investment.investor._cid === cid.value)
    } else {
      return portfolioStore.investments
    }
  }

  if (investorStore.state.selectedKeys.includes(cid.value)) {
    const disabled_cids = investorOptions.value
      .filter((option) => !investorStore.state.selectedKeys.includes(option.value))
      .map((option) => option.value)

    return portfolioStore.investments.filter((investment) => !disabled_cids.includes(investment.investor._cid))
  }
  return portfolioStore.investments.filter((investment) =>
    investorStore.state.selectedKeys.includes(investment.investor._cid),
  )
})

///////////////////////////////////////////////////////////////////////////////
// Fund performance
///////////////////////////////////////////////////////////////////////////////
const chartData = computed(() => {
  const entities = currentEntity.value.id
    ? transformEntity([currentEntity.value] || [], cid.value, true, investorStore.state.selectedKeys)
    : []
  return getChartData(entities, true, investorStore.state.selectedKeys)
})

const currentValue = computed(() => sum(investments.value.map((i) => i.current_value)) || initialMoney)
const calledReceived = computed(() => chartData.value.called_received)
const distributed = computed(() => chartData.value.distribution_distributed)

const xtvpi = computed(() =>
  tvpi({
    called: toNumber(calledReceived.value, true),
    current_value: toNumber(currentValue.value, true),
    distributed: toNumber(distributed.value, true),
  }),
)

const xrvpi = computed(() =>
  rvpi({
    called: toNumber(calledReceived.value, true),
    current_value: toNumber(currentValue.value, true),
  }),
)

const xdpi = computed(() =>
  dpi({
    called: toNumber(calledReceived.value, true),
    distributed: toNumber(distributed.value, true),
  }),
)

///////////////////////////////////////////////////////////////////////////////
// IRR is expensive to compute, so we use a web worker
///////////////////////////////////////////////////////////////////////////////

const cashflows = computed(() => {
  const today = dateFormat(new Date(), 'yyyy-MM-dd')
  let values: any

  values = flatten(filteredStats.value.map((s) => s.cashflows_received))

  values = [
    ...values,
    {
      amount: currentValue.value,
      date: today,
      type: 'distribution',
    },
  ]

  return values
    .map((cashflow: any) => {
      const amount = parse(cashflow.amount.amount, 'number')
      const actualAmount = cashflow.type === 'call' ? -amount : amount
      const date = new Date(cashflow.date)

      return {
        amount: actualAmount,
        date: date,
      }
    })
    .filter((a) => new Date(a.date) <= endOfDay(new Date()))
    .sort((a, b) => a.date - b.date)
})

const cashPosition = computed(() => sum(filteredStats.value.map((s) => s.cash_position)) || initialMoney)
const xxirr = computed(() => {
  if (toNumber(cashPosition.value, true) === 0 || !currentEntity.value.has_investments) return 0
  // transform cashflows into xirr format
  const values = cashflows.value.map((cashflow: any) => {
    return {
      amount: cashflow.amount,
      when: cashflow.date,
    }
  })

  try {
    return xirr(values)
  } catch (e) {
    return 0
  }
})

///////////////////////////////////////////////////////////////////////////////

const hasOutstandingSubscription = computed(() => {
  if (!currentEntity.value) return false
  return currentEntity.value.selected_subscriptions.some((stripe_subscription) =>
    ['past_due', 'incomplete', 'unpaid', 'trialing'].includes(stripe_subscription.status),
  )
})

const structure_data = computed(() => {
  const entity_carried_interest_percent =
    (parse(currentEntity.value.carried_interest_percentage || 0, 'number') as number) / 100
  const entity_management_fee_percent =
    (parse(currentEntity.value.management_fee_percentage || 0, 'number') as number) / 100
  const entity_preferred_return_percent =
    (parse(currentEntity.value.preferred_return_percentage || 0, 'number') as number) / 100

  if (
    !currentEntity.value.management_fee_percentage_array ||
    !currentEntity.value.carried_interest_percentage_array ||
    !currentEntity.value.preferred_return_percentage_array
  )
    return {
      management_fee_percent: entity_management_fee_percent,
      carried_interest_percent: entity_carried_interest_percent,
      preferred_return_percent: entity_preferred_return_percent,
    }

  const filtered_management_fee_percents = currentEntity.value.management_fee_percentage_array
    .filter((management_fee_percentage) => investorStore.state.selectedKeys.includes(management_fee_percentage._cid))
    .map((management_fee_percentage) => parse(management_fee_percentage.value, 'number'))
  const filtered_carried_interest_percents = currentEntity.value.carried_interest_percentage_array
    .filter((carried_interest_percentage) =>
      investorStore.state.selectedKeys.includes(carried_interest_percentage._cid),
    )
    .map((carried_interest_percentage) => parse(carried_interest_percentage.value, 'number'))
  const filtered_preferred_return_percents = currentEntity.value.preferred_return_percentage_array
    .filter((preferred_return_percentage) =>
      investorStore.state.selectedKeys.includes(preferred_return_percentage._cid),
    )
    .map((preferred_return_percentage) => parse(preferred_return_percentage.value, 'number'))

  const management_fee_percent = filtered_management_fee_percents[0] || 0
  const carried_interest_percent = filtered_carried_interest_percents[0] || 0
  const preferred_return_percent = filtered_preferred_return_percents[0] || 0

  const display_individual_percents =
    filtered_management_fee_percents.every((percent) => percent === management_fee_percent) &&
    filtered_carried_interest_percents.every((percent) => percent === carried_interest_percent) &&
    filtered_preferred_return_percents.every((percent) => percent === preferred_return_percent)

  if (display_individual_percents && investorStore.state.selectedKeys.length !== investorOptions.value.length) {
    return {
      management_fee_percent: management_fee_percent * 100,
      carried_interest_percent: carried_interest_percent * 100,
      preferred_return_percent: preferred_return_percent * 100,
    }
  }

  return {
    management_fee_percent: entity_management_fee_percent,
    carried_interest_percent: entity_carried_interest_percent,
    preferred_return_percent: entity_preferred_return_percent,
  }
})

const waterfall_info = computed(() => {
  switch (currentEntity.value?.structure?.waterfall) {
    case 'american':
      return 'American waterfall is calculated on a deal-by-deal basis, and a GP is compensated for each successful deal. This often allows the GP to begin taking a share of the profits, or carried interest, earlier in the life of a fund.'
      break

    case 'european':
      return "In a European waterfall, 100% of the contributed capital and preferred return is paid out to investors on a pro rata basis before the GP receives any distribution of carried interest. Because it's pro rata, all capital is treated equally, and distributions are paid out in proportion to the amount of capital invested."
      break

    default:
      return null
      break
  }
})
</script>

<template>
  <TheLayout>
    <!-- payment method message -->
    <VSection class="relative" v-if="false && hasOutstandingSubscription && isAdmin">
      <div class="rounded border-l-4 border-[#c98585] bg-[#dfabab] p-4">
        <p class="text-sm font-medium text-gray-700">
          This account is inactive. Click
          <RouterLink
            :to="{
              name: 'billing-entity',
              params: { billable_id: route.params.entity_id, billable_type: route.params.entity_type },
            }"
            class="text-[#3b88af] underline decoration-[#3b88af]/50 hover:text-gray-900 hover:decoration-gray-900/50"
          >
            here
          </RouterLink>
          to reactivate your account.
        </p>
      </div>
    </VSection>
    <TheHeader />
    <TheNav />
    <TheContent>
      <template #main>
        <PortfolioSection
          :is_portfolio_view_visible="false"
          :skeleton="skeleton"
          :investments="investments"
          :display_charts="false"
          :stack="true"
          :overview_display_data="['roi-moic', 'cash_position-distribution']"
          :cash_position="cashPosition"
          :no_data_config="{
            display: !skeleton && currentEntity.investors.length === 0,
            message: 'Add at least one investor to see portfolio data',
          }"
        />
        <VSection :label="capitalize(t(`shared.${formatEntityType(route.params.entity_type as string)} overview`))">
          <VStats
            class="-mt-px"
            :skeleton="skeleton"
            :stats="[
              {
                label: t('shared.XIRR'),
                info: `If you see a negative XIRR, but no change in investment value, this could be because of fees paid to execute the investment negatively impacting your return short-term.`,
                type: 'percent',
                value: xxirr,
              },
              {
                label: t('shared.TVPI'),
                type: 'multiple',
                value: xtvpi,
              },
              {
                label: t('shared.RVPI'),
                type: 'multiple',
                value: xrvpi,
              },
              {
                label: t('shared.DPI'),
                type: 'multiple',
                value: xdpi,
              },
            ]"
          />

          <div v-if="flags.debug" class="mt-6 select-text rounded-lg border bg-gray-100 p-6 text-gray-700">
            <pre class="text-xs">Debug: XIRR</pre>
            <pre class="text-xs">{{ cashflows }}</pre>
          </div>

          <div class="mt-6">
            <CallsBarChart
              :currency="currentEntity.currency"
              :committed="chartData.committed"
              :committed_remaining="chartData.committed_remaining"
              :called_received="chartData.called_received"
              :called_received_capital="chartData.called_received_capital"
              :called_received_management_fees="chartData.called_received_management_fees"
              :called_received_other_fees="chartData.called_received_other_fees"
              :called_pending="chartData.called_pending"
              :called_pending_capital="chartData.called_pending_capital"
              :called_pending_management_fees="chartData.called_pending_management_fees"
              :called_pending_other_fees="chartData.called_pending_other_fees"
              :skeleton="skeleton"
            />
          </div>

          <div class="mt-6">
            <DistributionsBarChart
              :currency="currentEntity.currency"
              :hurdle="chartData.hurdle"
              :distribution_distributed="chartData.distribution_distributed"
              :distribution_distributed_capital="chartData.distribution_distributed_capital"
              :distribution_distributed_profit="chartData.distribution_distributed_profit"
              :distribution_distributed_carried_interest="chartData.distributed_carried_interest"
              :distribution_distributed_other_fees="chartData.distributed_other_fees"
              :distribution_pending="chartData.distribution_pending"
              :distribution_pending_capital="chartData.distribution_pending_capital"
              :distribution_pending_profit="chartData.distribution_pending_profit"
              :distribution_pending_carried_interest="chartData.distribution_pending_carried_interest"
              :distribution_pending_other_fees="chartData.distribution_pending_other_fees"
              :distribution_remaining="chartData.distribution_remaining"
              :skeleton="skeleton"
            />
          </div>
        </VSection>
      </template>
      <template #aside>
        <VSection :label="capitalize(t('shared.general information'))">
          <template #actions v-if="isAdmin">
            <a :href="`${rails_url()}/funds/${route.params.entity_id}/edit#general-information`">
              <VButton size="xs">{{ capitalize(t('shared.edit')) }}</VButton>
            </a>
          </template>
          <template #default>
            <VDescriptionList
              v-if="isAdmin && currentEntity.is_venture360_the_administrator"
              class="mb-1"
              :items="[
                {
                  term: capitalize(t('shared.billing')),
                  description: capitalize(t('shared.view')),
                },
              ]"
              :skeleton="skeleton"
            >
              <template #description="{ item }">
                <RouterLink
                  :to="{
                    name: 'billing-entity',
                    params: { billable_id: route.params.entity_id, billable_type: route.params.entity_type },
                  }"
                  class="text-[#3b88af] underline decoration-[#3b88af]/50 hover:text-gray-900 hover:decoration-gray-900/50"
                >
                  {{ item.description }}
                </RouterLink>
              </template>
            </VDescriptionList>
            <VDescriptionList
              v-if="isAdmin && route.params.entity_type === 'fund'"
              class="mb-1"
              :items="[
                {
                  term: capitalize(t('shared.fund gp')),
                  description: currentEntity.fund_gp_name,
                },
              ]"
              :skeleton="skeleton"
            />
            <VDescriptionList
              class="mb-4"
              :items="[
                {
                  term: capitalize(t('shared.manager', currentEntity.managers.length)),
                  description: currentEntity.managers,
                },
              ]"
              :layout="currentEntity.managers.length > 1 ? 'stacked' : 'adjacent'"
              :skeleton="skeleton"
            >
              <template #description="{ item }">
                <span v-for="(manager, index) in currentEntity.managers" :key="manager.id">
                  <RouterLink
                    v-if="isAdmin"
                    class="text-[#3b88af] underline decoration-[#3b88af]/50 hover:text-gray-900 hover:decoration-gray-900/50"
                    :to="{ name: 'investing.individual-overview', params: { individual_id: manager.id } }"
                  >
                    {{ manager.name }}
                  </RouterLink>
                  <span v-else> {{ manager.name }} </span>
                  <span v-if="index !== currentEntity.managers.length - 1">, </span>
                </span>
              </template>
            </VDescriptionList>

            <VDescriptionList
              v-if="isAdmin"
              class="mb-4"
              :items="[
                {
                  term: capitalize(t('shared.type')),
                  description: currentEntity.type,
                },
                {
                  term: capitalize(t('shared.date established')),
                  description: dateFormat(new Date(currentEntity.date_established), 'PP'),
                },
                {
                  term: t('shared.EIN'),
                  description: currentEntity.tax_id,
                },
                {
                  term: capitalize(t('shared.currency')),
                  description: currentEntity.currency,
                },
              ]"
              :skeleton="skeleton"
            />
            <VDescriptionList
              v-if="isAdmin"
              :items="[{ term: capitalize(t('shared.address')), description: currentEntity.address }]"
              layout="stacked"
            >
              <template #description="{ item }">
                <VSkeletonBar v-if="skeleton" />
                <div v-else>
                  <div>{{ item.description.line1 }}</div>
                  <div v-if="!!item.description.line2">{{ item.description.line2 }}</div>
                  <div>
                    {{ item.description.city }} {{ item.description.state }}
                    {{ item.description.postal_code }}
                  </div>
                  <div>{{ item.description.country }}</div>
                </div>
              </template>
            </VDescriptionList>
          </template>
        </VSection>
        <VSection :label="capitalize(t('shared.structure'))">
          <template #actions v-if="isAdmin">
            <a :href="`${rails_url()}/funds/${route.params.entity_id}/edit#structure`">
              <VButton size="xs">{{ capitalize(t('shared.edit')) }}</VButton>
            </a>
          </template>
          <template #default>
            <VDescriptionList
              :items="[
                {
                  term: capitalize(t('shared.management fee')),
                  description: n(structure_data.management_fee_percent, 'percent'),
                },
                {
                  term: capitalize(t('shared.carried interest')),
                  description: n(structure_data.carried_interest_percent, 'percent'),
                },
                {
                  term: capitalize(t('shared.preferred return')),
                  description: n(structure_data.preferred_return_percent, 'percent'),
                },
              ]"
              :skeleton="skeleton"
            />
            <VDescriptionList
              v-if="route.params.entity_type === 'fund'"
              class="mt-4"
              :items="[
                {
                  term: capitalize(t('shared.capital call frequency')),
                  description: currentEntity.structure.capital_call_frequency || '--',
                },
                {
                  term: capitalize(t('shared.investment period')),
                  description: currentEntity.structure.investment_period
                    ? `${currentEntity.structure.investment_period} years`
                    : '--',
                },
                {
                  term: capitalize(t('shared.interest catch-up provision')),
                  description: format(currentEntity.structure.interest_catch_up_provision, 'boolean'),
                },
                {
                  term: capitalize(t('shared.waterfall')),
                  description: currentEntity.structure.waterfall || '--',
                  info: waterfall_info,
                },
              ]"
              :skeleton="skeleton"
            />
          </template>
        </VSection>
        <VSection
          v-if="isAdmin && route.params.entity_type === 'spv' && currentEntity.is_venture360_the_administrator"
          :label="capitalize(t('shared.other'))"
        >
          <template #actions>
            <a :href="`${rails_url()}/funds/${route.params.entity_id}/edit#setup`">
              <VButton size="xs">{{ capitalize(t('shared.edit')) }}</VButton>
            </a>
          </template>
          <template #default>
            <VDescriptionList
              v-if="isAdmin"
              class="mb-4"
              :items="[
                {
                  term: capitalize(t('shared.part of V360 holdings')),
                  description: format(currentEntity.questionnaire.uses_formation_documents, 'boolean'),
                },
                {
                  term: capitalize(t('shared.transferred from Assure')),
                  description: format(currentEntity.questionnaire.transferred_from_assure, 'boolean'),
                },
                {
                  term: capitalize(t('shared.part of consolidated return')),
                  description: format(currentEntity.questionnaire.part_of_consolidated_return, 'boolean'),
                },
                {
                  term: capitalize(t('shared.disregarded entity')),
                  description: format(currentEntity.questionnaire.disregarded_entity_for_tax_purposes, 'boolean'),
                },
                {
                  term: capitalize(t('shared.receives K-1')),
                  description: format(currentEntity.questionnaire.expects_to_receive_k1, 'boolean'),
                },
                {
                  term: capitalize(t('shared.annual activity')),
                  description: format(currentEntity.questionnaire.expects_activity_on_annual_basis, 'boolean'),
                },
              ]"
            />
            <VDescriptionList
              v-if="currentEntity.questionnaire.expected_activity !== null"
              :items="[{ term: 'Expects', description: currentEntity.questionnaire.expected_activity?.join(', ') }]"
              layout="stacked"
            />
          </template>
        </VSection>
      </template>
    </TheContent>
  </TheLayout>
</template>
